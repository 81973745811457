import {
  InjectedConnector,
  createClient,
  WalletConnectConnector,
} from "@gusdk/gu-wallet-connector";
import { env } from "../../libraries/utils/env";
import theme from "../../theme";

export const jocTestnet = {
  id: 10081,
  name: "Japan Open Chain Testnet",
  nativeCurrency: {
    name: "Japan Open Chain Testnet Token",
    symbol: "JOCT",
    decimals: 18,
  },
  rpcUrls: [
    "https://rpc-1.testnet.japanopenchain.org:8545",
    "https://rpc-2.testnet.japanopenchain.org:8545",
  ],
  blockExplorerUrls: ["https://explorer.testnet.japanopenchain.org/"],
};

export const joc = {
  id: 81,
  name: "Japan Open Chain Mainnet",
  nativeCurrency: {
    name: "Japan Open Chain Token",
    symbol: "JOC",
    decimals: 18,
  },
  rpcUrls: [
    "https://rpc-1.japanopenchain.org:8545",
    "https://rpc-2.japanopenchain.org:8545",
  ],
  blockExplorerUrls: ["https://mainnet.japanopenchain.org/"],
};

export const chains = [joc, jocTestnet];

export function useClient() {
  const client = createClient({
    connectors: [
      new InjectedConnector({
        chains,
      }),
      new WalletConnectConnector({
        chains,
        options: {
          projectId: env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
          qrModalOptions: {
            themeVariables: {
              "--wcm-z-index": theme.zIndex.modal + 1,
              "--w3m-z-index": theme.zIndex.modal + 1,
            } as any,
          },
        },
      }),
    ],
  });

  return client;
}
